import { userAxios } from '../../index'

const consultPriceList = async (perPage) => {
    try {
        return await userAxios.get(`consultationPrice/all/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const consultPriceCreate = async (data) => {
    try {
        return await userAxios.post('consultationPrice/default', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const consultPriceUpdate = async (id, data) => {
    try {
        return await userAxios.put(`consultationPrice/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const consultPriceDelete = async (id) => {
    try {
        return await userAxios.delete(`consultationPrice/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const consultPricePagination = async (perPage, page) => {
    try {
        return await userAxios.get(`consultationPrice/all/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const consultPriceFilter = async (paginate, data) => {
    try {
        return await userAxios.post(`consultationPrice/search/${paginate}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const consultPromoList = async (perPage) => {
    try {
        return await userAxios.get(`consultationPrice_promotion/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const consultPromoView = async (id) => {
    try {
        return await userAxios.get(`consultationPrice/campaigns/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const consultPromotionCreate = async (data) => {
    try {
        return await userAxios.post('consultationPrice/campaigns', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const consultPromoPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`consultationPrice_promotion/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    consultPriceList,
    consultPriceCreate,
    consultPriceUpdate,
    consultPriceDelete,
    consultPricePagination,
    consultPriceFilter,
    consultPromoList,
    consultPromoView,
    consultPromotionCreate,
    consultPromoPagination
}